<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 mx-auto mt-5">
        <div class="panel panel-info">
          <div class="panel-heading">
            <div class="panel-title">Sign In</div>
            <div style="float: right; font-size: 80%; position: relative; top: -10px">
              <a href="#">Forgot password?</a>
            </div>
          </div>

          <div style="padding-top: 30px" class="panel-body">
            <div v-if="error" id="login-alert" class="alert alert-danger col-sm-12">
              {{ error }}
            </div>

            <form class="form-horizontal">
              <div style="margin-bottom: 25px" class="input-group">
                <span class="input-group-addon"><i class="glyphicon glyphicon-user" /></span>
                <input v-model="email" type="text" class="form-control" placeholder="email" />
              </div>

              <div style="margin-bottom: 25px" class="input-group">
                <span class="input-group-addon"><i class="glyphicon glyphicon-lock" /></span>
                <input v-model="password" type="password" class="form-control" placeholder="password" />
              </div>

              <div style="margin-top: 10px" class="form-group">
                <div class="col-sm-12 controls">
                  <button class="btn btn-success" @click.prevent="logIn">Login</button>
                </div>
              </div>

              <div class="form-group mt-3">
                <div class="col-md-12 control">
                  <div style="border-top: 1px solid#888; padding-top: 15px; font-size: 85%">
                    Don't have an account!
                    <router-link to="/register"> Sign Up Here </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      loggedIn: false,
      email: '',
      password: '',
      error: '',
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['updateUserAuth']),
    logIn() {
      signInWithEmailAndPassword(getAuth(), this.email, this.password)
        .then((cred) => {
          const userAuth = {
            uid: cred.user.uid,
            userEmail: cred.user.email,
          };
          this.updateUserAuth(userAuth);
        })
        .catch((err) => {
          console.log(err.message);
          this.error = 'Something went wrong. Please try later.';
        });
    },
  },
};
</script>

<style></style>
